export const URLS = process.env.REACT_APP_USE_LOCAL_URLS === 'true'
  ? {
    SubmitTripReport: "<https://forms.office.com/Pages/ResponsePage.aspx?id=k7Mfu5XAokCK_dfJHd9PYino_2HfoZhBnf5BZmvl4bhUOUlESzRURjI4V01TQkQ3U0FVVkJGT1lSMSQlQCN0PWcu>",
    GetLatLng: "<https://maps.googleapis.com/maps/api/geocode/json?address=>",
    GetTimezone: "<https://maps.googleapis.com/maps/api/timezone/json?location=>",
    GetIp: "<https://api.db-ip.com/v2/free/self>",
    FlightawareTail: "<https://flightaware.com/live/flight/:tailNo>",
    SafeCharge: "<https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js>",
    FetchToken: "/api/acl/v1/authenticate",
    FetchTokenSSO: "/api/acl/v1/authenticate-sso",
    FetchRefreshToken: "/api/acl/v1/refreshjwt",
    AuthenticateUser: "/api/acl/v1/authenticate",
    BrokerBulkEdit: "/api/contact/v1/brokerInfo",
    BulkResend: "/api/jc-terms-conditions/v1/terms-and-conditions/resend/bulk",
    DeleteJetCard: "/api/jetcard/v1/jetcard",
    AircraftDelete: "/api/masterdatamanagement/v1/aircraft/delete",
    GetClientCardPreviewDetails: "/api/jetcard/v1/jetcard/cardclientpreview",
    GetAgreementPdf: "/api/jc-terms-conditions/v1/terms-and-conditions/pdfwithuid",
    HubspotJCListing: "/api/hubspot/v1/inquiry",
    HubspotTripListing: "/api/hubspot/v1/inquiry/trip",
    GlobalSearch: "/api/globalsearch/v1/globalsearch",
    GlobalSearchJetCardList: "/api/globalsearch/v1/globalsearch/jetcard",
    GlobalSearchContactList: "/api/globalsearch/v1/globalsearch/contact",
    GetNewToken: "/api/acl/v1/getTokenByRefreshToken",
    CreateContact: "/api/contact/v1/client",
    Getallassignedbrokers: "/api/contact/v1/getAllAssingnedBrokers",
    GetContacts: "/api/contact/v1/contacts/",
    GetDuplicateClients: "/api/wall/v1/validate/duplicate-clients",
    CreateJetCard: "/api/jetcard/v1/jetcard/create",
    GetJetCardPromotion: "/api/contact/v1/jetCardPromotions/promotions",
    JetCardPromotion: "/api/contact/v1/jetCardPromotions/promotions",
    UpdatePromotionStatus: "/api/contact/v1/jetCardPromotions/status",
    DeletePromotion: "/api/contact/v1/jetCardPromotions/delete",
    ValidateContact: "/api/contact/v1/client/validate",
    GetClientEmails: "/api/contact/v1/client/email",
    GetUserEmails: "/api/acl/v1/user/email/",
    GetClientBrokers: "/api/contact/v1/brokerInfo",
    GetClientPassenger: "/api/passenger/v1/passengers",
    AuthorizeAgreement: "/api/jetcard/v1/authorizeagreement",
    GetConfiguration: "/api/jetcard/v1/getconfiguration",
    GetTermsAndConditions: "/api/jc-terms-conditions/v1/terms-and-conditions/gettermandcondition",
    GetMinDailyHours: "/api/jetcard/v1/jet-cards-daily-hours",
    GetAirCraftRates: "/api/jetcard/v1/jet-cards-type-rate",
    GetTaxSurcharge: "/api/jetcard/v1/tax-surchage",
    UpdateJetCardDisplayName: "/api/jetcard/v1/jetcard/displayname",
    GetJetCardDetails: "/api/jetcard/v1/jetcard",
    GetClientCardDetails: "/api/jetcard/v1/jetcard/cardclientview",
    JetCardTransaction: "/api/jetcard/v1/jetcard/transaction",
    PostJetCardResend: "/api/jc-terms-conditions/v1/terms-and-conditions/resend",
    GetBrokerAssignmentUserName: "/api/users/v1/brokerassignment/users",
    GetBrokerAssignment: "/api/users/v1/brokerassignment",
    GetContactNotesByClientId: "/api/contact/v1/contact-notes/client-id",
    UpdateContactNotes: "/api/contact/v1/contact-notes",
    UpdateJetCardRates: "/api/jetcard/v1/jet-cards-type-rate",
    GetJetCardIdByContacts: "/api/jetcard/v1/jetcard/dropdown",
    GetTripIdByContacts: "/api/trip/v1/trip/dropdown",
    GetContactJetCardList: "/api/jetcard/v1/jetcard/overview",
    GetContactBillingAndClientNotes: "/api/jetcard/v1/jetcard/client-notes-and-billing-notes",
    UpdateJetCardBalanceStatement: "/api/jetcard/v1/jetcard/balance-statement",
    GetTripListing: "/api/trip/v1/trip",
    GetGlobalTripListing: "/api/globalsearch/v1/globalsearch/trip",
    GetTripLegs: "/api/trip/v1/trip/legs/{tripId}",
    TripBulkEdit: "/api/trip/v1/trip/bulk/brokers",
    GetTripAirports: "/api/trip/v1/trip/airports",
    Wyvernrating: "/api/masterdatamanagement/v1/wyvernrating",
    Priorityrating: "/api/masterdatamanagement/v1/priorityrating",
    Phonetype: "/api/masterdatamanagement/v1/phonetype",
    Argusrating: "/api/masterdatamanagement/v1/argusrating",
    Aircrafttype: "/api/masterdatamanagement/v1/aircrafttype",
    Emailtype: "/api/masterdatamanagement/v1/emailtype",
    AvinodeidExist: "/api/masterdatamanagement/v1/operator/avinodeidexist",
    GetPaxCapacity: "/api/masterdatamanagement/v1/aircraft/paxcapacity",
    GetTripAircraft: "/api/trip/v1/trip/aircraft",
    GetTripOperator: "/api/trip/v1/trip/operator",
    GetTripFBO: "/api/trip/v1/trip/fbo",
    GetSpecialRequestTypes: "/api/trip/v1/trip/specialrequesttypes",
    GetTripOverview: "/api/trip/v1/trip/tripoverview/:tripId",
    GetTripLegsByTripId: "/api/trip/v1/trip/legs/all/:tripId",
    GetTripSpecialRequestByTripId: "/api/trip/v1/trip/specialrequest/:tripId",
    PostTripOverview: "/api/trip/v1/trip/createtrip",
    PutTripOverview: "/api/trip/v1/trip/updatetrip",
    GetTripDetailsByTripId: "/api/trip/v1/trip/tripdetails/:tripId",
    GetFullTripDetailsByTripId: "/api/trip/v1/trip/tripfulldetails/:tripId",
    GetAcceptedTripDetailsByTripId: "/api/trip/v1/trip/acceptedtripdetails/:tripId",
    GetTripLegsScenarios: "/api/trip/v1/trip/legs/legsscenarios/:tripId",
    GetEditTripLegsOptions: "/api/trip/v1/trip/legs/legsoptions/:tripId",
    CreateOption: "/api/trip/v1/trip/legsoptions",
    GetJCProposal: "/api/trip/v1/trip/legs/jetcardproposal/:tripId",
    GetJCDetails: "/api/jetcard/v1/jetcard/:jcId",
    CreateProposal: "/api/trip/v1/trip/legs/jetcardproposal",
    CreateCharterProposal: "/api/trip/v1/trip/legs/charterproposal",
    SendTripReminderEmail: "/api/trip/v1/trip/sendemailreminder/:tripId",
    ContactPaymentInformation: "/api/contact/v1/paymentinformation/:clientId",
    GetTripDetailsByTripIdAndHash: "/api/trip/v1/trip/clientreview/:tripId/:hash",
    GetInvoiceDetailsByTripIdAndHash: "/api/trip/v1/trip/clientreview/invoice/:tripId/:hash",
    GetInvoiceDetailsByTripId: "/api/trip/v1/trip/invoice/:tripId",
    PostProposalAccepted: "/api/trip/v1/trip/acceptproposal",
    DownloadTripContract: "/api/trip/v1/trip/downloadcontract/:tripId",
    DownloadTripContractForClient: "/api/trip/v1/trip/clientreview/downloadcontract/:tripId/:hash",
    DownloadInvoiceByTripIdAndHash: "/api/trip/v1/trip/clientreview/downloadinvoice/:tripId/:hash",
    DownloadInvoiceByTripId: "/api/trip/v1/trip/downloadinvoice/:tripId",
    DownloadTripItinerary: "/api/trip/v1/trip/itinerarydownload",
    DeletePaymentInfoByTripIdAndPayId: "/api/trip/v1/trip/paymentInfo/:tripId/:hash/:payId",
    TripManage: "/api/trip/v1/trip/makeassignments",
    UpdateTripManageLeg: "/api/trip/v1/trip/updatelegsassignment",
    TripManageFinanceAdjustment: "/api/trip/v1/trip/financeadjustment",
    TripManageConfirmSendToFinance: "/api/trip/v1/trip/statusupdate/:tripId",
    PostFetchFinanceTripList: "/api/trip/v1/trip/tripsfinanceview",
    GetFinanceSendInvoice: "/api/trip/v1/trip/sendinvoice/:tripId",
    FinanceOperatorList: "/api/trip/v1/operator/payments",
    GetFinanceOperatorList: "/api/trip/v1/operator/payments/:legId",
    CancelTrip: "/api/trip/v1/trip/cancel",
    PostFetchFinanceOperatorList: "/api/trip/v1/operator/payments",
    PostAssignPassengerToTrip: "/api/trip/v1/trip/passengers",
    TripDownloadFiles: "/api/trip/v1/trip/files/:tripId",
    SendTripFiles: "/api/trip/v1/trip/files",
    UpdateTripFiles: "/api/trip/v1/trip/files/upload",
    SendDownloadTripFiles: "/api/trip/v1/trip/files/download",
    GetTripLegsByIds: "/api/trip/v1/trip/legs/tripids",
    PutTripReset: "/api/trip/v1/trip/edit/:tripId",
    GetTripIdListing: "/api/trip/v1/trip/dropdown",
    PostTripSearch: "/api/trip/v1/trip/dropdown",
    GetEteConfiguration: "/api/masterdatamanagement/v1/eteconfiguration",
    FileuploadUpload: "/api/fileupload/v1/file/upload",
    CreateTask: "/api/v1/task",
    EditTask: "/api/v1/task",
    GetTaskList: "/api/v1/task",
    GetSendTripFeedbackEmail: "/api/trip/v1/feedback/email/:tripId",
    GetTripSubmitFeedback: "/api/trip/v1/feedback/:tripId",
    GetTripSubmitFeedbackWithHash: "/api/trip/v1/feedback/trip/:tripId/feedback/:hash",
    PostTripSubmitFeedbackWithHash: "/api/trip/v1/feedback/trip/:tripId/feedback/:hash",
    GetTaskListByClientId: "/api/v1/task/client/:clientId",
    ChangeTaskStatus: "/api/v1/task/status",
    GetTaskListByTripId: "/api/v1/task/trip/:tripId",
    DeleteTask: "/api/v1/task/:taskId",
    GetActivityHistoryById: "/api/jetcard/v1/jet-cards-type-rate/auditlog/:jetCardTypeRateId",
    GetTripActivityLog: "/api/trip/v1/trip/activitylog/:tripId",
    GetTripLegActivityLog: "t/api/trip/v1/trip/legs/activitylog/:legId",
    GetTaskListByFilter: "/api/v1/task/filter",
    GetTripCount: "/api/trip/v1/trip/jetcardchartercountbyclientid/:clientId",
    GetPaymentSession: "/api/trip/v1/paymentgateway/getsessiontoken/:tripId/:hash",
    VisitNotification: "/api/notification/v1/contactnotifications/visit",
    GetNotifications: "/api/notification/v1/contactnotifications",
    GetActivityLogBroker: "/api/users/v1/brokerassignment/activitylog/:userId",
    GetPersonalInfoActivityLog: "/api/contact/v1/personalinfo/activitylog/:contactId",
    GetIdentityInformationActivityLog: "/api/contact/v1/identityinformation/activitylog/:contactId",
    GetBrokerAssignmentsActivityLog: "/api/contact/v1/brokerInfo/activityLog/:contactId",
    GetClientActivityLog: "/api/contact/v1/client/activitylog/:contactId",
    GetSpouseInfoActivityLog: "/api/contact/v1/spouseinfo/activitylog/:contactId",
    //   Emails Trigger
    GetTriggerJetCardAgreementViewEmail: "/api/jetcard/v1/jetcard/clientreviewactivity/:jetCardId",
    GetTriggerTripProposalViewEmail: "/api/trip/v1/trip/clientreviewactivity/:tripId",
    GetWeatherPoint: "<https://api.weather.gov/points/:lat,:lng>",
    //   PDF Download
    GetJetCardTermsPDFByAgreementId: "/api/jc-terms-conditions/v1/terms-and-conditions/download/:agreementId",
    GetTripTermsPDFByTripId: "/api/trip/v1/trip/terms-and-conditions/download/:tripId",
    GetDownloadedJetCardContractPDFUrl: "/api/jetcard/v1/authorizeagreement/jetcardcontracts/:jetCardId",
    GetReportsById: "/get-reports",
    PostFrontendError: "/api/log/frontend-error",
    // Retool
    GetRetoolEmbedUrl: '/retool/embed-url',
  }
  : {
    SubmitTripReport: `https://forms.office.com/Pages/ResponsePage.aspx?id=k7Mfu5XAokCK_dfJHd9PYino_2HfoZhBnf5BZmvl4bhUOUlESzRURjI4V01TQkQ3U0FVVkJGT1lSMSQlQCN0PWcu`,
    GetLatLng: 'https://maps.googleapis.com/maps/api/geocode/json?address=',
    GetTimezone: 'https://maps.googleapis.com/maps/api/timezone/json?location=',
    GetIp: 'https://api.db-ip.com/v2/free/self',
    FlightawareTail: 'https://flightaware.com/live/flight/:tailNo',
    SafeCharge: 'https://cdn.safecharge.com/safecharge_resources/v1/websdk/safecharge.js',
    FetchToken: '/authenticate',
    FetchTokenSSO: '/authenticate-sso',
    FetchRefreshToken: '/refreshaccesstoken',
    AuthenticateUser: '/authenticate',
    BrokerBulkEdit: '/contacts/brokerinfo',
    BulkResend: '/agreement/jc-terms-conditions/resend/bulk',
    DeleteJetCard: '/jetcard',
    AircraftDelete: 'master/aircraft/delete/',
    GetClientCardPreviewDetails: '/jetcard/cardclientpreview',
    GetAgreementPdf: '/agreement/terms-and-conditions/pdfwithuid',
    HubspotJCListing: '/hubspot/inquiry',
    HubspotTripListing: '/hubspot/inquiry/trip',
    GlobalSearch: "/globalsearch",
    GlobalSearchJetCardList: "/globalsearch/jetcard",
    GlobalSearchContactList: "/globalsearch/contact",
    GetNewToken: "/getTokenByRefreshToken",
    CreateContact: "/contacts/client",
    Getallassignedbrokers: "/contacts/getallassignedbrokers",
    GetContacts: "/contacts",
    GetDuplicateClients: "/wall/validate/duplicate-clients",
    CreateJetCard: "/contacts/jetcard",
    GetJetCardPromotion: "/contacts/jetcard/promotions",
    JetCardPromotion: "/contacts/jetcard/promotion",
    UpdatePromotionStatus: "/contacts/jetcard/promotion/status",
    DeletePromotion: "/contacts/jetcard/promotion/delete",
    ValidateContact: "/contacts/client/validate",
    GetClientEmails: "/contacts/client/email/",
    GetUserEmails: "/acl/user/email/",
    GetClientBrokers: "/contacts/brokerinfo/",
    GetClientPassenger: "/passenger/passengers/",
    AuthorizeAgreement: "/agreement/authorizeagreement",
    GetConfiguration: "/configuration/getconfiguration",
    GetTermsAndConditions: "/agreement/terms-and-conditions/gettermandcondition",
    GetMinDailyHours: "/contacts/jetcard/jet-cards-daily-hours",
    GetAirCraftRates: "/contacts/jetcard/jet-cards-type-rate",
    GetTaxSurcharge: "/agreement/jetcard/tax-surchage",
    UpdateJetCardDisplayName: "/agreement/jetcard/displayname",
    GetJetCardDetails: "/agreement/jetcard",
    GetClientCardDetails: "/jetcard/cardclientview",
    JetCardTransaction: "/jetcard/transaction",
    PostJetCardResend: "/agreement/terms-and-conditions/resend",
    GetBrokerAssignmentUserName: "/user/brokerassignment/users",
    GetBrokerAssignment: "/user/brokerassignment",
    GetContactNotesByClientId: "/contacts/notes/client-id",
    UpdateContactNotes: "/contacts/notes",
    UpdateJetCardRates: "/configuration/jet-cards-type-rate",
    GetJetCardIdByContacts: "/contacts/jetcard/dropdown",
    GetTripIdByContacts: "/trip/trip/dropdown",
    GetContactJetCardList: "/contacts/jetcard/overview",
    GetContactBillingAndClientNotes: "/contacts/jetcard/client-notes-and-billing-notes",
    UpdateJetCardBalanceStatement: "/jetcard/balance-statement",
    GetTripListing: "/trip",
    GetGlobalTripListing: "/globalsearch/trip",
    GetTripLegs: "/trip/legs",
    TripBulkEdit:"/trip/bulk/brokers",
    GetTripAirports:"/trip/airports",
    Wyvernrating:"/master/wyvernrating",
    Priorityrating:"/master/priorityrating",
    Phonetype:"/master/phonetype",
    Argusrating:"/master/argusrating",
    Aircrafttype:"/master/aircrafttype",
    Emailtype:"/master/emailtype",
    AvinodeidExist:"/master/operator/avinodeidexist",
    GetPaxCapacity : "master/aircraft/paxcapacity",
    GetTripAircraft:"/trip/aircraft",
    GetTripOperator:"/trip/operator",
    GetTripFBO:"/trip/fbo",
    GetSpecialRequestTypes:"/trip/specialrequesttypes",
    GetTripOverview: "/trip/:tripId",
    GetTripLegsByTripId: "/trip/scenariolegs/:tripId",
    GetTripSpecialRequestByTripId: "/trip/specialrequest/:tripId",
    PostTripOverview: "/trip/create",
    PutTripOverview: "/trip/update",
    GetTripDetailsByTripId: "/trip/detail/:tripId",
    GetFullTripDetailsByTripId: "/trip/detail/:tripId/full",
    GetAcceptedTripDetailsByTripId: "/trip/detail/:tripId/accepted",
    GetTripLegsScenarios:"/trip/legsscenarios/:tripId",
    GetEditTripLegsOptions:"/trip/legsoptions/:tripId",
    CreateOption: "/trip/legsoptions",
    GetJCProposal: "/trip/proposal/jetcard/:tripId",
    GetJCDetails: "/agreement/jetcard/:jcId",
    CreateProposal: "/trip/proposal/jetcard",
    CreateCharterProposal: "/trip/proposal/charter",
    SendTripReminderEmail: "/trip/proposal/reminderemail/:tripId",
    ContactPaymentInformation: "/contacts/paymentinfo/:clientId",
    GetTripDetailsByTripIdAndHash: "/trip/clientreview/:tripId/:hash",
    GetInvoiceDetailsByTripIdAndHash: "/trip/clientreview/invoice/:tripId/:hash",
    GetInvoiceDetailsByTripId: "/trip/invoice/:tripId",
    PostProposalAccepted: "/trip/proposal/accept",
    DownloadTripContract: "/trip/downloadcontract/:tripId",
    DownloadTripContractForClient: "/trip/clientreview/downloadcontract/:tripId/:hash",
    DownloadInvoiceByTripIdAndHash: "/trip/clientreview/invoice/:tripId/:hash/download",
    DownloadInvoiceByTripId: "/trip/invoice/:tripId/download",
    DownloadTripItinerary: "/trip/manage/itinerarydownload",
    DeletePaymentInfoByTripIdAndPayId: "/trip/clientreview/paymentinfo/:tripId/:hash/:payId",
    TripManage: "/trip/manage/makeassignment",
    UpdateTripManageLeg: "/trip/manage/updatelegsassignment",
    TripManageFinanceAdjustment : "/trip/manage/financeadjustment",
    TripManageConfirmSendToFinance : "/trip/manage/statusupdate/:tripId",
    PostFetchFinanceTripList: "/trip/tripsfinanceview",
    GetFinanceSendInvoice: "/trip/manage/sendinvoice/:tripId",
    FinanceOperatorList: "/trip/operator/payments",
    GetFinanceOperatorList: "/trip/operator/payments/:legId",
    CancelTrip : "/trip/cancel",
    PostFetchFinanceOperatorList: "/trip/operator/payments",
    PostAssignPassengerToTrip: "/trip/detail/passengers",
    TripDownloadFiles: "/trip/files/:tripId",
    SendTripFiles: "/trip/files",
    UpdateTripFiles: "/trip/files/upload",
    SendDownloadTripFiles: "/trip/files/download",
    GetTripLegsByIds: "/trip/legs/tripids",
    PutTripReset: "/trip/:tripId",
    GetTripIdListing: "trip/dropdown",
    PostTripSearch: "/trip/dropdown",
    GetEteConfiguration: "/master/eteconfiguration",
    FileuploadUpload: "fileupload/file/upload",
    CreateTask: "/task",
    EditTask: "/task",
    GetTaskList: "/task",
    GetSendTripFeedbackEmail: "/trip/feedback/email/:tripId",
    GetTripSubmitFeedback: "/trip/feedback/:tripId",
    GetTripSubmitFeedbackWithHash: "trip/feedback/:tripId/submit-feedback/:hash",
    PostTripSubmitFeedbackWithHash: "/trip/feedback/:tripId/submit-feedback/:hash",
    GetTaskListByClientId: "/task/client/:clientId",
    ChangeTaskStatus: "/task/status",
    GetTaskListByTripId: "/task/trip/:tripId",
    DeleteTask: "/task/:taskId",
    GetActivityHistoryById: "/configuration/jet-cards-type-rate/auditlog/:jetCardTypeRateId",
    GetTripActivityLog: "trip/:tripId/activitylog",
    GetTripLegActivityLog: "trip/leg/:legId/activitylog",
    GetTaskListByFilter: "task/filter",
    GetTripCount: "trip/client/:clientId/tripcount",
    GetPaymentSession: "/trip/:tripId/nuveisession/:hash",
    VisitNotification: "/notification/contactnotifications/visit",
    GetNotifications: "/notification/contactnotifications",
    GetActivityLogBroker: "/acl/user/:userId/activitylog",
    GetPersonalInfoActivityLog: "contacts/personalinfo/activitylog/:contactId",
    GetIdentityInformationActivityLog: "contacts/identityinformation/activitylog/:contactId",
    GetBrokerAssignmentsActivityLog: "contacts/brokerinfo/activitylog/:contactId",
    GetClientActivityLog: "contacts/client/activitylog/:contactId",
    GetSpouseInfoActivityLog: "contacts/spouseinfo/activitylog/:contactId",
    //   Emails Trigger
    GetTriggerJetCardAgreementViewEmail: "agreement/jetcard/clientreviewactivity/:jetCardId",
    GetTriggerTripProposalViewEmail: "/trip/clientreview/:tripId/activity",
    GetWeatherPoint: "https://api.weather.gov/points/:lat,:lng",
    //   PDF Download
    GetJetCardTermsPDFByAgreementId: "/agreement/terms-and-conditions/download/:agreementId",
    GetTripTermsPDFByTripId: "/trip/terms-and-conditions/download/:tripId",
    GetDownloadedJetCardContractPDFUrl: "/jetcard/authorizeagreement/jetcardcontracts/:jetCardId",
    GetReportsById: "/reports",
    PostFrontendError: "/frontend-error",
    // Retool
    GetRetoolEmbedUrl: '/retool/embed-url',
  };

export const buildAPIURL = (url: string, paramReplacements?: Record<string, any>, queryParam?: Record<string, any>) => {
  if (paramReplacements) {
    url = replaceParams(url, paramReplacements);
  }
  if (queryParam) {
    url = addQueryParameters(url, queryParam);
  }
  return url;
};

const replaceParams = (url: string, params: Record<string, any>) => {
  Object.keys(params).forEach(k => {
    url = url.replace(`:${k}`, encodeURIComponent(params[k]));
  });
  return url;
};

export const addQueryParameters = (url: string, params: Record<string, any>) => {
  const queryList: Array<string> = [];
  Object.keys(params).forEach(k => {
    queryList.push(`${k}=${encodeURIComponent(params[k])}`);
  });
  const prefixCharacter = url.indexOf('?') === -1 ? '?' : '&';
  return `${url + prefixCharacter + queryList.join('&')}`;
};
